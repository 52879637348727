<template>
  <Layout>
    <PageHeader :items="items"/>

    <!-- FILTER START--->
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="col-md-2" style="width:12%"
               v-if="login_type == 1 || login_type == 3 || this.$storageData.profile.empTypeID == 9">
            <label class="form-label" for="formrow-voucherType-input" style="font-size: 12px;">Select Country </label>
            <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                         @input="getCorporateList()" :show-labels="false"></multiselect>
          </div>
          <div class="col-md-2" style="width:20%" v-if="login_type == 1 || this.$storageData.profile.empTypeID == 9">
            <label class="form-label" style="font-size: 12px;">Select Corporate </label>
            <multiselect v-model="corporateID" :options="corporateArr" track-by="corporateID" label="corporateName"
                         @input="getCorporateCities();" :show-labels="false"></multiselect>
          </div>
          <div class="col-md-2" style="width:12%"
               v-if="login_type == 1 || login_type == 3 || this.$storageData.profile.empTypeID == 5 || this.$storageData.profile.empTypeID == 9">

            <label style="font-size: 12px;">Select City </label>
            <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                         @input="getCorporateBranchByCorpID();" :show-labels="false"></multiselect>
          </div>
          <div class="col-md-2" style="width:20%"
               v-if="login_type == 1 || login_type == 3 || this.$storageData.profile.empTypeID == 5 || this.$storageData.profile.empTypeID == 9">
            <label class="form-label" style="font-size: 12px;">Select Corporate Branch </label>
            <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                         :show-labels="false"></multiselect>
            <!-- @input="onchangeBranch();" -->
          </div>

          <!-- For priciples -->
          <div class="col-md-2" style="width:12%"
               v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 && this.$storageData.login_type==4">
            <label>Select Country </label>
            <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                         @input="getPrincipleCities();" :show-labels="false"></multiselect>
          </div>
          <div class="col-md-2" style="width:12%"
               v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 && this.$storageData.login_type==4">

            <label>Select City </label>
            <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                         @input="getPrincipleBranchByPrincipleID();" :show-labels="false"></multiselect>
          </div>
          <div class="col-md-2" style="width:20%"
               v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 && this.$storageData.login_type==4">

            <label>Select Principle Branch </label>
            <multiselect v-model="principleBranchID" :options="principleBranches" track-by="principleBranchID"
                         label="principleBranchName"
                         @input="getPrincipleCorporateBranches();" :show-labels="false"></multiselect>
          </div>
          <div class="col-md-2" style="width:20%"
               v-if="this.$storageData.profile.empTypeID==7 || this.$storageData.profile.empTypeID==18 || this.$storageData.profile.empTypeID==8 && this.$storageData.login_type==4">
            <label class="form-label" style="font-size: 12px;">Select Corporate Branch </label>
            <multiselect v-model="branchID" :options="branches" track-by="corpBranchID" label="corpBranchName"
                         :show-labels="false"></multiselect>
            <!-- @input="onchangeBranch();" -->
          </div>
          <!-- For priciples -->

          <div class="col-sm-6 col-md-2" style="width:12%">
            <label class="form-label" for="formrow-voucherType-input" style="font-size: 12px;">Transaction Type</label>
            <multiselect v-model="typeID" :options="transactionTypeArr" :show-labels="false" track-by="typeID"
                         label="name">
              <!-- @input="applyFilter();"> -->
            </multiselect>
          </div>


          <div class="col-sm-6 col-md-3" style="margin-top:10px;">
            <label class="form-label" style="font-size: 12px;" for="formrow-date-input">Date</label>
            <date-picker v-model="dateFilter" format="DD MMM Y" range append-to-body lang="en" confirm>
              <!-- @input="applyFilter();"> -->
            </date-picker>
          </div>
          <!-- Apply filter -->
          <div class="col-md-2" style="width:auto; margin-top:auto;">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()"> Apply Filter</button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width:auto; margin-top:auto;">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()"> Clear Filter</button>
          </div>
          <!-- Clear filter END-->
          <!-- <div class="col-sm-6 col-md-1 mt-3">
                <button class="btn btn-themeBrown" style="padding: 4px 8px;margin-top: 16px;" @click="clearFilter();"> Clear </button>
          </div> -->
        </div>
      </div>
    </div>

    <!--FITER END--->

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Wallet Report Overview</span>
              </template>
              <div class="row" style="margin-top: 10px;">
                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ walletCardData.walletPointsAdded }}
                          <!-- <span data-plugin="counterup">
                            <countTo :startVal="1000" :endVal="voucherData.totalAmountRedeemed" :duration="2000"></countTo>
                          </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total Points Assigned</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ walletCardData.walletPointsUsed }}
                          <!-- <span data-plugin="counterup">
                              <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                          </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total Points Redeemed</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ walletCardData.walletPointsExpired }}
                          <!-- <span data-plugin="counterup">
                              <countTo :startVal="1000" :endVal="voucherData.redeemedVouchers" :duration="2000"></countTo>
                          </span> -->
                        </h4>
                        <p class="text-muted mb-0">Total Points Expired</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                                  <span data-plugin="counterup">
                                      <countTo :startVal="0" :endVal="walletCardData.totalAssignedUsers > 0 ? walletCardData.totalAssignedUsers:0"
                                               :duration="2000"></countTo>
                                  </span>
                        </h4>
                        <p class="text-muted mb-0">Assigned Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">Wallet Graph</h4>
                    <!-- Bar Chart -->
                    <!-- {{this.walletStatData.chartOptions}} -->
                    <!-- <v-frappe-chart
                        type="line"
                        :labels="walletStatData.series"
                        :data="walletStatData.chartOptions"
                    /> -->
                    <apexchart
                        class="apex-charts"
                        height="339"
                        type="area"
                        dir="ltr"
                        :series="walletStatData.series"
                        :options="walletStatData.chartOptions"
                    ></apexchart>
                    <!-- <apexchart type="line" class="apex-charts" dir="ltr" height="339" :options="salesGraph.chartOptions" :series="salesGraph.series"></apexchart> -->
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                  </span>
                <span class="d-none d-sm-inline-block">Wallet Report List</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-4" style="margin-bottom:15px;" v-if="login_type == 1">
                        <div role="group" class="btn-group">
                          <!-- <button type="button" class="btn btn-themeBrown">Excel</button>  -->
                          <button type="button" class="btn btn-themeBrown" @click="downloadSample();">Excel</button>
                          <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select style="margin-left:5px; margin-right:5px"
                                           v-model="perPage"
                                           size="sm"
                                           :options="pageOptions"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-4 row">
                        <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                                v-model="filter"
                                type="search"
                                placeholder="Search..."
                                class="form-control form-control-sm ms-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">

                      <b-table
                          striped hover
                          outlined
                          bordered
                          :items="tableData"
                          :fields="fields"
                          responsive
                          :per-page="perPage"
                          :current-page="currentPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          @filtered="onFiltered"
                          foot-clone
                          :busy="isReportTableBusy"
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(transactionType)="data">
                          <span v-if="data.item.event =='EXPIRED'" style="color:#F3766A;"> Expired </span>
                          <span v-else-if="data.item.transactionType==1" style="color:green;"> Credit </span>
                          <span v-else-if="data.item.transactionType==2" style="color:#A4827D;"> Debit </span>
                        </template>
                        <template v-slot:cell(points)="data">
                          <span v-if="data.item.points">{{ data.item.points }} </span>
                        </template>
                        <template v-slot:cell(orderID)="data">
                          <div v-if="login_type == 3 || login_type == 4">
                            <span v-if="data.item.orderID">
                              {{ data.item.orderID }}
                            </span>
                            <span v-else> - </span>
                          </div>
                          <div v-else>
                            <span v-if="data.item.orderID" style="cursor:pointer; color: rgb(243, 118, 106);"
                                  @click="viewOrderDetails(data.item.id);">
                              {{ data.item.orderID }}
                            </span>
                            <span v-else> - </span>
                          </div>
                        </template>
                        <template v-slot:foot(employeeName)>
                          <span>Total: </span>
                        </template>
                        <template v-slot:foot(employeeID)>
                          <span></span>
                        </template>
                        <template v-slot:foot(corpBranchName)>
                          <span></span>
                        </template>
                        <template v-slot:foot(transactionType)>
                          <span></span>
                        </template>
                        <template v-slot:foot(points)>
                          <span>{{ $func.formatNumber(walletStatsTotalPoints) }}</span>
                        </template>
                        <template v-slot:foot(orderID)>
                          <span></span>
                        </template>
                        <template v-slot:foot(restaurant)>
                          <span></span>
                        </template>
                        <template v-slot:foot(created)>
                          <span></span>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                            ></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept :orderDetails="this.orderDetails" :orderedItemArr="this.orderedItemArr"
                  :orderStatusArr="this.orderStatusArr">
    </OrderReciept>

  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import countTo from "vue-count-to";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";
import DatePicker from "vue2-datepicker";

export default {
  page: {
    title: "Wallet Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    countTo,
    Multiselect,
    OrderReciept,
    DatePicker,
    //VFrappeChart,
  },
  data() {
    return {
      login_type: 0,
      isReportTableBusy: false,
      corporateArr: [],
      corporateID: '',
      tableData: [],
      walletCardData: [],
      templateArr: [],
      restBranchArr: [],
      orderDetails: [],
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      restBranchID: "",
      templateID: "",
      countryArr: [],
      country: "",
      transactionTypeArr: [
        {'typeID': 1, 'name': 'Credit'},
        {'typeID': 2, 'name': 'Debit'},
        {'typeID': 3, 'name': 'Expired'},
      ],
      typeID: "",
      dateFilter: [],
      branches: [],
      branchID: "",
      principleID: "",
      principleBranchID: "",
      principleBranches: [],
      cityArr: [],
      city: "",
      title: "Wallet Statistics",
      items: [
        {
          text: "View",
        },
        {
          text: "Wallet Statistics",
          active: true,
        },
      ],
      corpBranchID: "",
      walletStatData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "employeeName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "employeeID",
          label: "Customer ID",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "corpBranchName",
          sortable: true,
        },
        {
          key: "transactionType",
          sortable: true,
        },
        {
          key: "points",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "orderID",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.corpBranchID = (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) ? this.brandLogin() : ""; //this.getStats();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },

  mounted() {
    this.login_type = this.$storageData.login_type;
    this.totalRows = this.items.length;

    if (this.$storageData.login_type == 1 || this.$storageData.profile.empTypeID == 9) {
      this.getAllCountry();
    } else if (this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11) {
      this.getCorporateCountry();
    } else if (this.$storageData.login_type == 4) {
      this.getPrincipleCountry();
    }

    ///this.readWalletStats();
  },
  methods: {
    /**
     * Search the table data with search input
     */

    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole + "/getAllCountry", {
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.pid,
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.countryArr = response.data.data;
            // this.country = (this.countryArr) ? this.countryArr[0] : "";
            // this.getAllCities();
          });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
          .post(this.$loggedRole + "/getAllCities", {
                country: (this.country) ? this.country.country : "",
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.pid,
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            //this.city = (this.cityArr) ? this.cityArr[0] : "";
            if (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) {
              this.getCorporateBranchByCorpID();
            }


          });
    },
    getPrincipleCountry() {
      this.axios
          .post(this.$loggedRole + "/getPrincipleCountry", {
                principleID: this.$storageData.profile.principleID,
                isDashboard: 1, // for all dropdown in only dashboards
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.principleAdminID,
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.countryArr = response.data.data;
            // this.country = (this.countryArr) ? this.countryArr[0] : "";
            this.getPrincipleCities();

          });
    },
    getPrincipleCities() {
      this.axios
          .post(this.$loggedRole + "/getPrincipleCities", {
                principleID: this.$storageData.profile.principleID,
                country: (this.country) ? this.country.country : "",
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.principleAdminID,
                isDashboard: 1, // for all dropdown in only dashboards
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            // this.city = (this.cityArr) ? this.cityArr[0] : "";
            this.getPrincipleBranchByPrincipleID();

          });
    },
    getPrincipleBranchByPrincipleID() {
      this.axios
          .post(this.$loggedRole + "/getPrincipleBranchByPrincipleID",
              {
                principleID: this.$storageData.profile.principleID,
                city: (this.city) ? this.city.city : "",
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.principleAdminID,
                isDashboard: 1, // for all dropdown in only dashboards
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.principleBranches = response.data.data;
            // this.principleBranchID = this.principleBranches[0];
            // this.principleBranchIDSelected = this.principleBranchID.principleBranchID;
            // console.log(this.principleBranchID);
            // this.getPrincipleCorporateBranches();


          });
    },
    getPrincipleCorporateBranches() {
      this.axios
          .post(this.$loggedRole + "/getPrincipleCorporateBranches", {
                principleBranchID: this.principleBranchID.principleBranchID,
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.principleAdminID,
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.branches = response.data.data;
            //  this.branchID = (this.branches) ? this.branches[0] : [];
            //  this.principleCorporateStatistics();
          });
    },
    brandLogin() {
      this.getCorporateCountry();
    },
    getCorporateCountry() {
      this.axios
          .post(this.$loggedRole + "/getCorporateCountry", {
                corporateID: this.$storageData.profile.corporateID,
                flag: (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) ? 0 : 1, // for all dropdown in only dashboards
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.pid,
                loginTypeID: this.$storageData.login_type
              }
          )
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = (this.countryArr) ? this.countryArr[0] : "";
            this.getCorporateCities();

          });
    },
    getCorporateCities() {
      var corpID = this.$storageData.profile.corporateID;
      if (this.corporateID.corporateID && this.corporateID.corporateID != "") {
        corpID = this.corporateID.corporateID;
      }
      this.axios
          .post(this.$loggedRole + "/getCorporateCities", {
                corporateID: corpID,
                flag: (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) ? 0 : 1, // for all dropdown in only dashboards
                country: (this.country) ? this.country.country : "",
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.pid,
                loginTypeID: this.$storageData.login_type,
                corpBranchID:this.$storageData.profile.corpBranchID ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = (this.cityArr) ? this.cityArr[0] : "";
            this.getCorporateBranchByCorpID();


          });
    },
    getCorporateList() {
      this.corporateArr = [];
      this.corporateID = "";
      this.axios
          .get(this.$loggedRole + "/getCorporateList", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type
          })
          .then((response) => {
            this.corporateArr = response.data.data;
          });
    },

    getCorporateBranchByCorpID() {

      var corporateID = this.corporateID.corporateID;
      if (this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 11 || this.$storageData.profile.empTypeID == 5) {
        corporateID = this.$storageData.profile.corporateID;
      }
      this.axios
          .post(this.$loggedRole + "/getCorporateBranchByCorpID", {
                corporateID: corporateID,
                flag: (this.$storageData.login_type == 1 || this.$storageData.login_type == 3 || this.$storageData.profile.empTypeID == 5) ? 0 : 1, // for all dropdown in only dashboards
                city: (this.city) ? this.city.city : "",
                empTypeID: this.$storageData.profile.empTypeID,
                empID: this.$storageData.profile.pid,
                loginTypeID: this.$storageData.login_type,
            corpBranchID:this.$storageData.profile.corpBranchID ? this.$storageData.profile.corpBranchID:"",
              }
          )
          .then((response) => {
            this.branches = response.data.data;
            // this.branchID = this.branches[0];
            this.branchID = this.branchID.corpBranchID;
            // this.readWalletStats();

          });
    },
    onchangeBranch() {
      this.corpBranchID = this.branchID.corpBranchID;
      // this.readWalletStats();
    },
    getStats() {
      this.corpBranchID = this.$storageData.profile.corpBranchID;
      this.readWalletStats();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readWalletStats() {
      this.isReportTableBusy = true;

      var principleID = (this.$storageData.profile.principleID && this.$storageData.profile.principleID != 0) ? this.$storageData.profile.principleID : 0;
      var principleBranchID = (this.$storageData.profile.principleBranchID && this.$storageData.profile.principleBranchID != 0) ? this.$storageData.profile.principleBranchID : this.principleBranchID != "" ? this.principleBranchID.principleBranchID : 0;
      this.axios
          .post(this.$loggedRole + "/getWalletStatistics", {
                'principleID': principleID,
                'principleBranchID': principleBranchID,
                'empTypeID': this.$storageData.profile.empTypeID,
                'empID': this.$storageData.profile.principleAdminID,
                'corpBranchID': this.corpBranchID,
                'transactionType': (this.typeID) ? this.typeID.typeID : 0,
                'dateFilter': this.dateFilter,
                'loginTypeID': this.$storageData.login_type,
                'country': this.country.country,
                'city': this.city.city,
                'princBranchData': this.principleBranches,
              }
          )
          .then((response) => {
            this.isReportTableBusy = false;
            this.tableData = response.data.data;
            this.walletCardData = response.data.walletCardData;
            this.walletStatData = response.data.walletStatData;
            this.walletStatsTotalPoints = response.data.walletStatsTotalPoints;
          });
    },
    applyFilter() {
      if (this.dateFilter.includes(null)) {
        this.dateFilter = [];
      }
      this.corpBranchID = (this.branchID != "" && this.branchID != null) ? this.branchID.corpBranchID : 0;
      // this.corpBranchID = (this.$storageData.profile.empTypeID==1 || this.$storageData.profile.empTypeID==5 || this.$storageData.profile.empTypeID==7) ? this.branchID.corpBranchID : this.$storageData.profile.corpBranchID;
      this.readWalletStats();

    },
    clearFilter() {
      this.corpBranchID = "";
      this.corporateID = 0;
      this.branchID = 0;
      this.typeID = 0;
      this.restBranch = "";
      this.principleBranchID = "";
      this.restBranchArr = [];
      this.city = "";
      this.country = "";
      this.typeID = 0;
      this.dateFilter = [];
      this.readWalletStats();
    },
    downloadSample() {
      this.axios.post('exportExcel', {
        exportData: this.tableData,
        page: "Wallet"
      }, {
        responseType: 'blob'
      }).then((response) => {
        const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Wallet Report.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },

    viewOrderDetails(orderID) {
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios.post(this.$loggedRole + "/getOrderDetailsByID", {'orderID': orderID})
          .then((result) => {

            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
            //  console.log(result.data.data.orderedItemArr);
            this.orderedItemArr = result.data.data.orderedItemArr;
            // this.orderItemAddonArr = result.data.orderItemAddonArr;

          });
    },

  },
  middleware: "authentication",
};
</script>
